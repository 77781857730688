import { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navigation from "./components/Navigation";
import { UserProvider, UserWrapper } from "./hooks/useUser";
import { ConfigProvider } from "./hooks/useConfig";
import { Toaster } from "react-hot-toast";
import Header from "./components/Header";
import Map from "./components/Map";

import BusinessView from "./views/business";

import styles from "./App.module.css";

export default function App({ isTelegram }: { isTelegram: boolean }) {
  const finishLoading = () => {
    document.querySelector("html")!.classList.remove("loading");
    console.log(
      `Axium: Empire App loaded, running in ${
        isTelegram ? "Telegram" : "Browser"
      }`
    );
  };
  useEffect(() => {
    if (window.configData.ready) return finishLoading();
    console.log(`Waiting for config data...`);
    window.addEventListener("config-loaded", finishLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [trayOpen, setTrayOpen] = useState(false);

  return (
    <ConfigProvider>
      <Toaster position="bottom-center" reverseOrder={false} />
      <UserProvider isTelegram={isTelegram}>
        <BrowserRouter>
          <UserWrapper>
            <Header />
            <Navigation />
            <Map />
            <div className={`${styles.tray} ${trayOpen && styles.expanded}`}>
              <button
                className={styles.toggle}
                onClick={() => setTrayOpen(!trayOpen)}
              />
              <Routes>
                <Route path="/" element={<BusinessView />} />
              </Routes>
            </div>
          </UserWrapper>
        </BrowserRouter>
      </UserProvider>
    </ConfigProvider>
  );
}
