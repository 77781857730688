import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import useManager from "../../hooks/useManager";

import styles from "./styles.module.css";

export default function MapWrapper() {
  const viewport = window.viewport;
  return (
    <div
      className={styles.root}
      style={{
        width: viewport[0],
        height: viewport[1],
      }}
    >
      <Map />
    </div>
  );
}

const ZoomRange = [1, 2.5];
const mapSize: [width: number, height: number] = [1085, 1578 + 158 + 92];

function Map() {
  const viewport = window.viewport;

  const imageRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!imageRef.current || !sliderRef.current)
      return console.error("Ref not found");

    const slider = sliderRef.current,
      image = imageRef.current;

    const originalImageDimensions = {
      width: mapSize[0],
      height: mapSize[1],
    };
    const virtualWindowSize = {
      width: viewport[0],
      height: viewport[1],
    };
    const virtualWindowAspectRatio =
      virtualWindowSize.width / virtualWindowSize.height;

    let isDragging = false;
    let startX: number, startY: number, initialX: number, initialY: number;
    let zoomLevel = 1; // Initialize zoom level to 1

    const adjustedDimensions = {
      height: originalImageDimensions.height,
      width: originalImageDimensions.width,
    };

    const maxAdjustment = {
      x:
        (Math.abs(virtualWindowSize.width - adjustedDimensions.width) / 2) *
        zoomLevel,
      y:
        (Math.abs(virtualWindowSize.height - adjustedDimensions.height) / 2) *
        zoomLevel,
    };

    function updateDimensions() {
      const aspectRatio =
        originalImageDimensions.width / originalImageDimensions.height;
      //console.log("aspect ratio", aspectRatio);
      //console.log("virtualWindowSize", virtualWindowSize);
      /* if the image is 16:9, then the image should be scaled to fit the window */
      if (Math.abs(aspectRatio - virtualWindowAspectRatio) < 0.01) {
        adjustedDimensions.height = virtualWindowSize.height * zoomLevel;
        adjustedDimensions.width = virtualWindowSize.width * zoomLevel;
      } else if (aspectRatio === 1) {
        /* if the image is a square, then the image should be scaled so the width fits the window */
        adjustedDimensions.width = virtualWindowSize.width * zoomLevel;
        adjustedDimensions.height = virtualWindowSize.width * zoomLevel;
      } else if (aspectRatio < virtualWindowAspectRatio) {
        /* if the image is taller than 16:9, then the width should be the window size and the height should be scaled */
        // console.log("taller than 16:9");
        adjustedDimensions.width = virtualWindowSize.width * zoomLevel;
        adjustedDimensions.height = adjustedDimensions.width / aspectRatio;
      } else {
        /* if the image is wider than 16:9, then the height fit the window and the width will be scaled */
        //console.log("wider than 16:9");
        adjustedDimensions.height = virtualWindowSize.height * zoomLevel;
        adjustedDimensions.width = adjustedDimensions.height * aspectRatio;
      }

      image.style.height = `${adjustedDimensions.height}px`;
      image.style.minHeight = `${adjustedDimensions.height}px`;
      image.style.width = `${adjustedDimensions.width}px`;
      image.style.minWidth = `${adjustedDimensions.width}px`;

      maxAdjustment.x =
        Math.abs(virtualWindowSize.width - adjustedDimensions.width) / 2;
      maxAdjustment.y =
        Math.abs(virtualWindowSize.height - adjustedDimensions.height) / 2;

      const transform = window.getComputedStyle(image).transform;
      const currentTransform = { x: 0, y: 0 };
      if (transform !== null && transform !== "none") {
        const transformValues = (
          transform.match(/matrix.*\((.+)\)/) as any
        )[1].split(", ");
        currentTransform.x = parseInt(transformValues[4], 10);
        currentTransform.y = parseInt(transformValues[5], 10);
      }
      if (
        currentTransform.x > maxAdjustment.x ||
        currentTransform.x < -maxAdjustment.x ||
        currentTransform.y > maxAdjustment.y ||
        currentTransform.y < -maxAdjustment.y
      ) {
        image.style.transform = `translate(${Math.min(
          maxAdjustment.x,
          Math.max(-maxAdjustment.x, currentTransform.x)
        )}px, ${Math.min(
          maxAdjustment.y,
          Math.max(-maxAdjustment.y, currentTransform.y)
        )}px)`;
      }
    }

    updateDimensions();

    const onSliderChange = () => {
      const sliderValue = Number(slider.value);
      if (isNaN(sliderValue)) return;

      zoomLevel =
        ZoomRange[0] + (sliderValue / 200) * (ZoomRange[1] - ZoomRange[0]); // Convert the slider value to a scale factor
      console.log(`New zoom level: ${zoomLevel}x`);
      updateDimensions();
    };

    const updateZoomLevel = (newZoomLevel: number) => {
      zoomLevel = Math.max(ZoomRange[0], Math.min(ZoomRange[1], newZoomLevel)); // Clamp zoom level within range
      console.log(`New zoom level: ${zoomLevel}x`);
      updateDimensions(); // Update the image dimensions based on the new zoom level
    };

    //slider.addEventListener("input", onSliderChange);

    const onMouseDown = (e: MouseEvent) => {
      if (e.button !== 0) return (isDragging = false);
      e.stopPropagation();
      e.preventDefault();
      isDragging = true;

      startX = e.clientX;
      startY = e.clientY;

      const transform = window.getComputedStyle(image).transform;
      if (transform !== null && transform !== "none") {
        const transformValues = (
          transform.match(/matrix.*\((.+)\)/) as any
        )[1].split(", ");
        initialX = parseInt(transformValues[4], 10);
        initialY = parseInt(transformValues[5], 10);
      } else {
        initialX = 0;
        initialY = 0;
      }
    };

    image.addEventListener("mousedown", onMouseDown);
    /* scroll wheel zooming */
    let animationFrame: number | null = null;
    const onWheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const delta = e.deltaY;
      const zoomFactor = 0.03;
      const newZoomLevel = zoomLevel + (delta > 0 ? -zoomFactor : zoomFactor);
      updateZoomLevel(newZoomLevel); // Directly update zoom level
    };
    image.addEventListener("wheel", onWheel);

    const onMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;

      e.stopPropagation();
      e.preventDefault();

      const dx = e.clientX - startX;
      const dy = e.clientY - startY;
      const newTranslate = {
        x: Math.max(-maxAdjustment.x, Math.min(maxAdjustment.x, initialX + dx)),
        y: Math.max(-maxAdjustment.y, Math.min(maxAdjustment.y, initialY + dy)),
      };
      image.style.transform = `translate(${newTranslate.x}px, ${newTranslate.y}px)`;
    };

    document.addEventListener("mousemove", onMouseMove);

    const stopDrag = () => {
      isDragging = false;
    };

    window.addEventListener("blur", stopDrag);
    document.addEventListener("mouseup", stopDrag);

    let initialDistance = 0;

    function getTouchDistance(touches: TouchList) {
      // @ts-ignore
      const [touch1, touch2] = touches;
      const dx = touch1.clientX - touch2.clientX;
      const dy = touch1.clientY - touch2.clientY;
      return Math.sqrt(dx * dx + dy * dy);
    }

    const onTouchStart = (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.touches.length === 1) {
        // Single finger drag
        isDragging = true;
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
        const transform = window.getComputedStyle(image).transform;
        if (transform !== null && transform !== "none") {
          const transformValues = (
            transform.match(/matrix.*\((.+)\)/) as any
          )[1].split(", ");
          initialX = parseInt(transformValues[4], 10);
          initialY = parseInt(transformValues[5], 10);
        } else {
          initialX = 0;
          initialY = 0;
        }
      } else if (e.touches.length === 2) {
        // Pinch zoom start
        initialDistance = getTouchDistance(e.touches);
      }
    };

    const onTouchMove = (e: TouchEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (e.touches.length === 1 && isDragging) {
        // Handle drag movement
        const dx = e.touches[0].clientX - startX;
        const dy = e.touches[0].clientY - startY;
        const newTranslate = {
          x: Math.max(
            -maxAdjustment.x,
            Math.min(maxAdjustment.x, initialX + dx)
          ),
          y: Math.max(
            -maxAdjustment.y,
            Math.min(maxAdjustment.y, initialY + dy)
          ),
        };
        image.style.transform = `translate(${newTranslate.x}px, ${newTranslate.y}px)`;
      } else if (e.touches.length === 2) {
        // Handle pinch zoom movement
        const newDistance = getTouchDistance(e.touches);
        const zoomFactor = (newDistance - initialDistance) / 500; // Adjust zoom sensitivity
        updateZoomLevel(zoomLevel + zoomFactor); // Directly update zoom level
      }
    };

    const onTouchEnd = () => {
      isDragging = false;
    };

    image.addEventListener("touchstart", onTouchStart);
    image.addEventListener("touchmove", onTouchMove);
    image.addEventListener("touchend", onTouchEnd);

    return () => {
      console.log("Stopping drag and removing event listeners");
      stopDrag();
      if (animationFrame !== null) cancelAnimationFrame(animationFrame);
      //slider.removeEventListener("input", onSliderChange);
      image.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("blur", stopDrag);
      document.removeEventListener("mouseup", stopDrag);
      image.removeEventListener("wheel", onWheel);
      image.removeEventListener("touchstart", onTouchStart);
      image.removeEventListener("touchmove", onTouchMove);
      image.removeEventListener("touchend", onTouchEnd);
    };
  }, []);

  return (
    <div
      className={styles.container}
      style={{
        width: viewport[0],
        height: viewport[1],
      }}
    >
      <div className={styles.sliderWrapper}>
        <span>–</span>
        <input
          type="range"
          ref={sliderRef}
          min="0"
          max="200"
          defaultValue="0"
          className={styles.slider}
        />
        <span>＋</span>
      </div>
      <div className={styles.gradient} />
      <div className={styles.canvas} draggable={false} ref={imageRef}>
        <div className={styles.outline} />
        <div className={styles.fill} />
      </div>
    </div>
  );
}
