import { Big } from "big.js";

export function api(...fragments: (string | number)[]): string {
  return `${process.env.REACT_APP_API_BASE}/api/${fragments
    .map((fragment) =>
      typeof fragment !== "string" ? fragment.toString() : fragment
    )
    .filter((fragment, i) => i !== 0 || fragment !== "api")
    .map((fragment, i, arr) =>
      // do not remove trailing slash for the last fragment
      i === arr.length - 1
        ? fragment.replace(/^\//, "")
        : fragment.replace(/^\/|\/$/g, "")
    )
    .filter((fragment) => fragment !== "")
    .join("/")}`;
}

export function between(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function toLocaleString(
  value: Big | string | number | null | undefined,
  forceString = false
): React.ReactNode {
  Big.PE = 100;
  if (value === null || typeof value === "undefined") return "0";
  if (typeof value === "number") {
    if (value < 10000000) return value.toLocaleString();
    return toLocaleString(new Big(value));
  }
  if (typeof value === "string") return toLocaleString(new Big(value));

  if (value.gt(10000000) && !forceString) {
    const str = value.toString();
    const exponent = str.length - 3;
    const firstDigit = str[0];
    const nextDigits = str.slice(1, 3);

    return (
      <>
        {firstDigit}.{nextDigits}
        <sup>{exponent}</sup>
      </>
    );
  }

  const valueString = value.toString();
  let stylizedValue = "";

  // check if locale deliminator is a comma or period
  const deliminator = (1234).toLocaleString().includes(",") ? "," : ".";

  // add deliminator to the value
  for (let i = 0; i < valueString.length; i++) {
    if (i !== 0 && i % 3 === 0) stylizedValue = deliminator + stylizedValue;
    stylizedValue = valueString[valueString.length - 1 - i] + stylizedValue;
  }

  return stylizedValue;
}
