import { useState, useEffect, useCallback, useContext } from "react";

import { createContext } from "react";
import { BusinessData } from "../data/bebop.gen";
import Big from "big.js";

import type { IBusinessLevelData } from "../data/bebop.gen";
import type { BusinessLevel } from "../types/business";

type ConfigPatterns = { [patternName: string]: BusinessLevel[] };
type ConfigMilestones = { [milestoneName: string]: number[] };
interface ConfigContextType {
  ready: boolean;
  patterns: ConfigPatterns;
  milestones: ConfigMilestones;
}

const ConfigContext = createContext<ConfigContextType>({
  ready: false,
  patterns: {},
  milestones: {},
});

export default function useConfig(): ConfigContextType {
  const state = useContext(ConfigContext);

  return state;
}

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const [patterns, setPatterns] = useState<ConfigPatterns | null>(null);
  const [milestones, setMilestones] = useState<ConfigMilestones | null>(null);

  const serializePatterns = useCallback((levels: ArrayBuffer) => {
    console.log("Serializing patterns:", levels);
    const businessData = BusinessData.decode(new Uint8Array(levels));

    let patterns: ConfigPatterns = {},
      milestones: ConfigMilestones = {};
    businessData.data.forEach((pattern) => {
      //console.log("Pattern:", pattern.name, pattern.milestoneExpr);
      milestones[pattern.name] = pattern.milestoneExpr
        .split(";")
        .map((e: string) => parseInt(e));
      patterns[pattern.name] = pattern.levels
        .map((level: IBusinessLevelData) => ({
          level: level.level,
          cost: new Big(level.cost.toString()),
          earn: new Big(level.earn.toString()),
          maxCollect: level.maxCollect,
          time: level.time,
          name: pattern.name,
        }))
        .sort((a, b) => a.level - b.level);
    });

    setPatterns(patterns);
    setMilestones(milestones);
  }, []);

  useEffect(() => {
    if (window.configData.ready)
      return serializePatterns(window.configData.level);

    const eventHandler = () => {
      serializePatterns(window.configData.level as ArrayBuffer);
    };
    window.addEventListener("config-loaded", eventHandler);

    return () => window.removeEventListener("config-loaded", eventHandler);
  }, [serializePatterns]);

  return (
    <ConfigContext.Provider
      value={{
        ready: patterns !== null,
        patterns: patterns || {},
        milestones: milestones || {},
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}
