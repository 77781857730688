import useUser from "../../hooks/useUser";
import useConfig from "../../hooks/useConfig";
import { useEffect, useMemo, useState } from "react";
import useNow from "../../hooks/useNow";

import styles from "./styles.module.css";
import { toLocaleString } from "../../utils";
import config from "../../config";
import Big from "big.js";
import {
  collectBuildingIncome,
  confirmBuildingChoice,
  getPossibleBuildingChoices,
  upgradeBuilding,
} from "../../api/building";
import ms from "ms";
import toast from "react-hot-toast";

import type { Building } from "../../types/building";

import BuildingProgress, {
  BuildingProgressBar,
  BuildingProgressInfo,
} from "./progress";

const DistrictColors: {
  [districtId: string]: string;
} = Object.freeze({
  "1": "#31A2CE",
  "2": "#3153CE",
  "3": "#31CEAC",
  "4": "#C93654",
  "5": "#D95B26",
  "6": "#D926A4",
});

export default function BusinessView() {
  const {
    buildings,
    user,
    isTelegram,
    logout,
    _api,
    buildingsManager,
    districts,
    setUser,
  } = useUser();

  return (
    <>
      {/*<header className={styles.header}>
        <div className={styles.row}>
          <div className={styles.gems}>💎 {toLocaleString(user!.gem)}</div>
          <button
            className={styles.avatar}

            onClick={() => (isTelegram ? null : logout())}
          />
        </div>
        <h2 className={styles.money}>${toLocaleString(user!.money)}</h2>
        <p className={styles.wallet}>
          {true
            ? user?.username
            : !user?.eth_wallet
            ? "No wallet linked"
            : user?.eth_wallet}
        </p>
      </header>*/}
      <div className={styles.scroller}>
        {buildings.map((building) => (
          <div
            className={styles.building}
            key={building.id}
            style={
              {
                "--accent": DistrictColors[building.district_id || "1"] ?? "",
              } as React.CSSProperties
            }
          >
            <picture className={styles.image}>
              {building.is_active && (
                <div className={styles.reputation}>★{building.loyalty}</div>
              )}
            </picture>
            {!building.is_active && (
              <div className={styles.select}>
                {building.meta.district_id.split(",").map((districtId) => (
                  <button
                    className={styles.choice}
                    key={districtId}
                    onClick={async () => {
                      const result = await confirmBuildingChoice(
                        _api,
                        building.id,
                        districtId
                      );

                      if (!result.success) {
                        toast.error(result.error);
                        return;
                      }

                      if (isTelegram)
                        window.Telegram.WebApp.HapticFeedback.impactOccurred(
                          "heavy"
                        );

                      console.log("upgraded", result);

                      buildingsManager.update(building.id, result.building);

                      if (!result.spend.eq(0))
                        toast.error(`-$${toLocaleString(result.spend, true)}`);
                      setUser((user) => ({ ...user!, money: result.money }));
                    }}
                  >
                    {districtId === "10"
                      ? "NOT WORKING"
                      : districts === null
                      ? "Unknown"
                      : districts[districtId]?.district?.display_name ||
                        "Unknown"}{" "}
                    {districtId}
                  </button>
                ))}
              </div>
            )}
            {building.is_active && <ActiveBuilding building={building} />}
          </div>
        ))}
        {buildings.length < config.MaxBusinesses && (
          <button
            className={styles.add}
            onClick={async () => {
              const result = await getPossibleBuildingChoices(_api);

              if (!result.success) {
                toast.error(result.error);
                return;
              }

              buildingsManager.add(result.building);
              if (isTelegram)
                window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");

              console.log(result);
            }}
          >
            <picture className={styles.image} />
            <div className={styles.body}>Open new business</div>
          </button>
        )}
      </div>
    </>
  );
}

function ActiveBuilding({ building }: { building: Building }) {
  const { _api, buildingsManager, setUser, isTelegram, districts, user } =
    useUser();
  const { patterns, ready: configReady, milestones } = useConfig();

  const BusinessMilestones = useMemo(
    () => milestones[building.business_pattern_id].sort((a, b) => a - b),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configReady, building.business_pattern_id]
  );
  const [LastMilestone, NextMilestone] = useMemo(() => {
    let nextIndex = BusinessMilestones.findIndex(
      (milestone) => milestone > building.level
    );
    if (nextIndex === -1) nextIndex = BusinessMilestones.length - 1;

    if (nextIndex === 0) return [0, BusinessMilestones[0]];
    return [BusinessMilestones[nextIndex - 1], BusinessMilestones[nextIndex]];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BusinessMilestones, building.level]);

  useEffect(() => {
    if (!configReady) return;
    console.log(
      "BusinessMilestones",
      building.business.name,
      BusinessMilestones
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configReady]);

  /*const [baseLevel, currentLevel] = useMemo(() => {
    if (building.level === building.business.max_level)
      return [building.level - LastMilestone, NextMilestone - LastMilestone];

    const base = LastMilestone;

    return [base, building.level - base];
  }, [
    building.level,
    building.business.max_level,
    LastMilestone,
    NextMilestone,
  ]);*/

  const [upgradableLevels, upgradeableLevelsCost] = useMemo(() => {
    if (
      !configReady ||
      !patterns[building.business_pattern_id] ||
      building.level === building.business.max_level
    )
      return [0, new Big(0)];

    const businessLevels = patterns[building.business_pattern_id];
    let businessNextLevels = businessLevels.slice(
      businessLevels.findIndex((level) => level.level === building.level)
    );
    businessNextLevels = businessNextLevels.slice(
      0,
      NextMilestone - building.level
    );

    /*console.log(
      "businessNextLevels",
      building.business.name,
      [LastMilestone, NextMilestone],
      businessNextLevels.map((l) => l.level)
    );*/

    /*console.log(
      `Building ${building.business.name} is level ${building.level}, last milestone was ${LastMilestone}, next milestone is ${NextMilestone}, next levels are`,
      `${businessNextLevels.map((l) => l.level)[0]} to ${
        businessNextLevels.map((l) => l.level)[businessNextLevels.length - 1]
      }`
    );*/

    // We don't know what the next level is, so we can't calculate the progress
    if (businessNextLevels.length === 0) return [0, new Big(0)];

    if (
      !user?.money ||
      user.money.eq(0) ||
      user.money.lt(businessNextLevels[0].cost)
    )
      return [0, businessNextLevels[0].cost];

    let money: Big = user!.money,
      upgradableLevels = 0,
      upgradeableLevelsCost = new Big(0);

    for (const level of businessNextLevels) {
      if (money.gte(level.cost)) {
        money = money.minus(level.cost);
        upgradeableLevelsCost = upgradeableLevelsCost.plus(level.cost);
        upgradableLevels++;
      } else break;
    }

    return [upgradableLevels, upgradeableLevelsCost];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.money,
    configReady,
    building.level,
    building.business.max_level,
    NextMilestone,
  ]);

  const remainingLevels = useMemo(
    () => NextMilestone - building.level - upgradableLevels,
    [NextMilestone, building.level, upgradableLevels]
  );

  return (
    <div className={styles.body}>
      <div className={styles.info}>
        <div className={styles.left}>
          <div className={styles.district}>
            {building.district_id === null || districts === null
              ? "Unknown"
              : districts[building.district_id]?.district?.display_name ||
                "Unknown"}
            {building.district_id}
          </div>
          <div className={styles.name}>
            {building.business.name} [{building.business_id}]
          </div>
        </div>
        <button
          className={styles.upgrade}
          disabled={upgradableLevels === 0}
          onClick={async () => {
            const result = await upgradeBuilding(
              _api,
              building.id,
              upgradableLevels
            );

            if (!result.success) {
              toast.error(result.error);
              return;
            }

            if (
              !result.earn.eq(0) &&
              building.business_level_data.maxCollect !== 0
            )
              toast.success(`+$${result.earn}`);
            toast.error(`-$${toLocaleString(result.cost, true)}`);

            if (result.cost.eq(upgradeableLevelsCost))
              console.log("Upgrade cost calculated correctly");
            else
              console.warn(
                `Upgrade cost mismatch, expected ${toLocaleString(
                  upgradeableLevelsCost,
                  true
                )} but got ${toLocaleString(
                  result.cost,
                  true
                )} (${toLocaleString(
                  result.cost.minus(upgradeableLevelsCost),
                  true
                )}) higher`,
                {
                  expected: upgradeableLevelsCost.toString(),
                  actual: result.cost.toString(),
                  difference: result.cost
                    .minus(upgradeableLevelsCost)
                    .toString(),
                }
              );

            if (isTelegram)
              window.Telegram.WebApp.HapticFeedback.notificationOccurred(
                "success"
              );

            buildingsManager.update(building.id, result.building);
            setUser((user) => ({ ...user!, money: result.money }));
          }}
        >
          <span>
            {upgradableLevels > 1 ? `x${upgradableLevels} ` : ""}UPGRADE
          </span>
          {building.level === building.business.max_level ? (
            "MAX"
          ) : (
            <div>${toLocaleString(upgradeableLevelsCost)}</div>
          )}
        </button>
      </div>
      {/*<BusinessProgress building={building} />*/}
      <BuildingProgressInfo
        base={LastMilestone}
        currentLevel={building.level - LastMilestone}
        remaining={remainingLevels}
        upgradable={upgradableLevels}
      />
      {building.business_level_data.maxCollect === 0 ? (
        <AutoBusinessEarnings building={building} />
      ) : building.business_level_data.maxCollect === 1 ? (
        <BusinessEarnings building={building} />
      ) : (
        <SemiAutoBusinessEarnings building={building} />
      )}
    </div>
  );
}
function AutoBusinessEarnings({ building }: { building: Building }) {
  return (
    <div className={`${styles.earnings} ${styles.auto}`}>
      <div className={styles.texture} />
      <span className={styles.time}>{"auto".toUpperCase()}</span>
      <span>
        ${toLocaleString(building.business_level_data.earn)}/
        {building.business_level_data.time === 1
          ? "s"
          : ms(building.business_level_data.time * 1000)}
      </span>
    </div>
  );
}
function SemiAutoBusinessEarnings({ building }: { building: Building }) {
  /* use server provided times initially */
  const [nextCollect, setNextCollect] = useState(() =>
    Math.floor(new Date(building.next_collect).valueOf() / 1000)
  );
  const [lastCollect, setLastCollect] = useState(() =>
    Math.floor(new Date(building.last_collect).valueOf() / 1000)
  );

  const now = useNow(nextCollect);
  const { isTelegram, _api, buildingsManager, setUser } = useUser();

  const [capacity, setCapacity] = useState(() =>
    Math.min(
      building.business_level_data.maxCollect,
      Math.floor((now - lastCollect) / building.business_level_data.time)
    )
  );
  const [accrued, setAccrued] = useState(() =>
    building.business_level_data.earn.times(capacity)
  );

  /* when collected, last collect time changes */
  useEffect(() => {
    /* real last collect time */
    const lastCollect = Math.floor(
      new Date(building.last_collect).valueOf() / 1000
    );
    let now = Math.floor(Date.now() / 1000);

    /* how many times has the building been collected */
    const capacity = Math.min(
      building.business_level_data.maxCollect,
      Math.floor((now - lastCollect) / building.business_level_data.time)
    );
    setCapacity(capacity);
    /* is the building full */
    const full = capacity >= building.business_level_data.maxCollect;

    /* both next and last aren't real because they are calculated off of the current time if the building is full */
    /* that means they are just there for UI reasons and could be completely wrong */

    /* if the building is full, the last collect time is now minus the time it takes to fill the building */
    /* otherwise, the last collect time is the last time the building was collected */
    const virtualLastCollect = full
      ? now - building.business_level_data.time
      : lastCollect + capacity * building.business_level_data.time;
    setLastCollect(virtualLastCollect);

    /* if the building is full, the next collect time is now */
    const nextCollect = full
      ? now
      : virtualLastCollect + building.business_level_data.time;

    setNextCollect(nextCollect);

    /* if the building is full, the capacity is the max collect */
    /* otherwise, the capacity is the amount of times the building has been collected so far */
    setAccrued(building.business_level_data.earn.times(capacity));

    /* if the building is full, nothing else needs to be done */
    if (full) return console.log("Building is full");

    let interval: NodeJS.Timeout | null = null,
      timeout: NodeJS.Timeout | null = setTimeout(
        () => {
          now = Math.floor(Date.now() / 1000);

          const capacity = Math.min(
            building.business_level_data.maxCollect,
            Math.floor((now - lastCollect) / building.business_level_data.time)
          );

          setCapacity(capacity);
          setAccrued(building.business_level_data.earn.times(capacity));

          if (capacity >= building.business_level_data.maxCollect)
            return console.log("Building is full");

          const virtualLastCollect =
            lastCollect + capacity * building.business_level_data.time;
          const virtualNextCollect =
            virtualLastCollect + building.business_level_data.time;
          setLastCollect(virtualLastCollect);
          setNextCollect(virtualNextCollect);

          interval = setInterval(
            () => {
              now = Math.floor(Date.now() / 1000);

              const capacity = Math.min(
                building.business_level_data.maxCollect,
                Math.floor(
                  (now - lastCollect) / building.business_level_data.time
                )
              );

              setCapacity(capacity);
              setAccrued(building.business_level_data.earn.times(capacity));

              if (capacity >= building.business_level_data.maxCollect) {
                clearInterval(interval!);
                interval = null;
                return console.log("Building is full");
              }

              const virtualLastCollect =
                lastCollect + capacity * building.business_level_data.time;
              const virtualNextCollect =
                virtualLastCollect + building.business_level_data.time;
              setLastCollect(virtualLastCollect);
              setNextCollect(virtualNextCollect);
            },
            (virtualNextCollect - now) * 1000
          );
          timeout = null;
        },
        (nextCollect - now) * 1000
      );

    return () => {
      if (interval) clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building.last_collect]);

  return (
    <button
      className={`${styles.earnings} ${
        capacity < building.business_level_data.maxCollect && styles.disabled
      }`}
      disabled={accrued.eq(0)}
      onClick={async () => {
        if (accrued.eq(0)) return;

        if (isTelegram)
          window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");

        const result = await collectBuildingIncome(_api, building.id);

        if (!result.success) {
          toast.error(result.error);
          return;
        }

        toast.success(`+$${toLocaleString(result.earn, true)}`);

        buildingsManager.update(building.id, result.building);
        setAccrued(new Big(0));
        setUser((user) => ({ ...user!, money: result.money }));
      }}
    >
      <div className={styles.capacity}>
        <span
          style={{
            height: `${Math.min(
              100,
              Math.max(
                10,
                (capacity / building.business_level_data.maxCollect) * 100
              )
            )}%`,
          }}
        />
      </div>
      <span>${toLocaleString(accrued)}</span>
      <span className={styles.time}>
        {nextCollect <= now
          ? "collect".toUpperCase()
          : ms((nextCollect - now) * 1000)}{" "}
        ({capacity} / {building.business_level_data.maxCollect})
      </span>
      <span
        className={styles.fill}
        style={{
          width: `${Math.max(
            0,
            Math.min(
              100,
              ((now - lastCollect + 1.5) / (nextCollect - lastCollect)) * 100
            )
          )}%`,
          opacity: now >= nextCollect ? 0 : 1,
          transition:
            ((now - lastCollect) / (nextCollect - lastCollect)) * 100 < 10
              ? "none"
              : "width 1s linear, opacity 0.2s ease-in",
        }}
      />
    </button>
  );
}
function BusinessEarnings({ building }: { building: Building }) {
  const nextCollect = useMemo(
    () => Math.floor(new Date(building.next_collect).valueOf() / 1000),
    [building.next_collect]
  );
  const lastCollect = useMemo(
    () => Math.floor(new Date(building.last_collect).valueOf() / 1000),
    [building.last_collect]
  );

  const now = useNow(nextCollect);

  const { _api, buildingsManager, setUser, isTelegram } = useUser();

  /*useEffect(() => {
    if (nextCollect > now && false)
      console.log(
        `Building ${building.business.name} is not ready yet`,
        {
          nextCollect,
          nextCollectString: building.next_collect,
          now,
          nowString: new Date().toISOString(),
        },
        nextCollect - now
      );
    else if (false)
      console.log(
        `Building ${building.business.name} is ready to collect income`
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextCollect, now]);*/

  return (
    <button
      className={`${styles.earnings}`}
      disabled={
        nextCollect > now || building.business_level_data.maxCollect === 0
      }
      onClick={async () => {
        if (nextCollect > now) return;
        if (building.business_level_data.maxCollect === 0) return;

        if (isTelegram)
          window.Telegram.WebApp.HapticFeedback.notificationOccurred("success");

        const result = await collectBuildingIncome(_api, building.id);

        if (!result.success) {
          toast.error(result.error);
          return;
        }
        toast.success(`+$${result.earn}`);

        buildingsManager.update(building.id, result.building);
        setUser((user) => ({ ...user!, money: result.money }));
      }}
    >
      <span className={styles.time}>
        {building.business_level_data.maxCollect === 0
          ? "auto".toUpperCase()
          : nextCollect > now
          ? ms(Math.max(1000, (nextCollect - now) * 1000))
          : "collect".toUpperCase()}
      </span>
      {building.business_level_data.maxCollect === 1 && (
        <span>${toLocaleString(building.business_level_data.earn)}</span>
      )}
      {building.business_level_data.maxCollect === 0 && (
        <span>
          ${toLocaleString(building.business_level_data.earn)}/
          {building.business_level_data.time === 1
            ? "s"
            : ms(building.business_level_data.time * 1000)}
        </span>
      )}
      {building.business_level_data.maxCollect > 0 && (
        <div
          className={styles.fill}
          style={{
            width: `${Math.max(
              0,
              Math.min(
                100,
                ((now - lastCollect + 1) / (nextCollect - lastCollect)) * 100
              )
            )}%`,
            opacity: now >= nextCollect ? 0 : 1,
            transition:
              ((now - lastCollect + 1) / (nextCollect - lastCollect)) * 100 < 1
                ? "none"
                : "width 1s linear, opacity 0.2s ease-in",
          }}
        />
      )}
    </button>
  );
}

function Countdown({ to, end }: { to: number; end: string }) {
  const [now, setNow] = useState(() => Math.floor(Date.now() / 1000));

  useEffect(() => {
    const i = setInterval(() => {
      const newNow = Math.floor(Date.now() / 1000);
      if (newNow >= to) {
        clearInterval(i);
      }
      setNow(newNow);
    }, 1000);

    return () => clearInterval(i);
  }, [to]);

  return <>{now < to ? ms(Math.max(1000, (to - now) * 1000)) : end}</>;
}
