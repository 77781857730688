import { useReducer } from "react";

type EntityId = number | string;

// Define a base type that ensures all entities have an id field
interface BaseEntity {
  id: EntityId;
}

export interface Manager<Entity extends BaseEntity> {
  // Ensure Entity extends BaseEntity
  add: (entity: Entity) => void;
  remove: (entityId: EntityId) => void;
  update: (entityId: EntityId, entity: Partial<Entity>) => void;
  replace: (entityId: EntityId, entity: Entity) => void;
  get: (entityId: EntityId) => Entity | undefined;
  getAll: () => Entity[];
  clear: () => void;
  setAll: (entities: Entity[]) => void;
}

export default function useManager<Entity extends BaseEntity>(
  initialState: Entity[]
): [Entity[], Manager<Entity>] {
  const reducer = (state: Entity[], action: any) => {
    switch (action.type) {
      case "add":
        return [...state, action.entity];
      case "remove":
        return state.filter((entity) => entity.id !== action.entityId);
      case "update":
        return state.map((entity) =>
          entity.id === action.entityId
            ? { ...entity, ...action.entity }
            : entity
        );
      case "replace":
        return state.map((entity) =>
          entity.id === action.entityId ? action.entity : entity
        );
      case "clear":
        return [];
      case "setAll":
        return action.entities;

      default:
        return state;
    }
  };

  const [entities, dispatch] = useReducer(reducer, initialState);

  const manager: Manager<Entity> = {
    add: (entity) => dispatch({ type: "add", entity }),
    remove: (entityId) => dispatch({ type: "remove", entityId }),
    update: (entityId, entity) =>
      dispatch({ type: "update", entity, entityId }),
    replace: (entityId, entity) =>
      dispatch({ type: "replace", entity, entityId }),
    get: (entityId) =>
      entities.find((entity: Entity) => entity.id === entityId),
    getAll: () => entities,
    clear: () => dispatch({ type: "clear" }),
    setAll: (entities) => dispatch({ type: "setAll", entities }),
  };

  return [entities, manager];
}

export function fakeManager<Entity extends BaseEntity>(
  initialState: Entity[]
): Manager<Entity> {
  const manager: Manager<Entity> = {
    add: () => {},
    remove: () => {},
    update: () => {},
    replace: () => {},
    get: () => undefined,
    getAll: () => initialState,
    clear: () => {},
    setAll: () => {},
  };

  return manager;
}
