import type { ApiBuilding, Building } from "../types/building";
import type {
  ResultExtendable,
  BootstrapFetch,
  pAPIResponse,
} from "../types/common";
import type { District } from "../types/district";

import { api } from "../utils";
import Big from "big.js";

export async function getPossibleBuildingChoices(_api: BootstrapFetch): Promise<
  ResultExtendable<{
    building: Building;
    districts: District[];
  }>
> {
  const result = await fetch(
    api("building"),
    _api({
      method: "POST",
    })
  ).then(
    (r) =>
      r.json() as pAPIResponse<{ building: Building; districts: District[] }>
  );

  if (typeof result.statusCode === "number")
    return {
      success: false,
      error: result.message,
    };

  return {
    success: true,
    building: {
      ...result.building,
      /*business_level_data: {
        ...result.building.business_level_data,
        earn: new Big(result.building.business_level_data.earn),
        cost: new Big(result.building.business_level_data.cost),
      },*/
    },
    districts: result.districts,
  };
}

export async function confirmBuildingChoice(
  _api: BootstrapFetch,
  buildingId: number,
  districtId: string
): Promise<ResultExtendable<{ building: Building; money: Big; spend: Big }>> {
  const result = await fetch(
    api("building", buildingId),
    _api(
      {
        method: "POST",
        body: JSON.stringify({ district: Number(districtId) }),
      },
      true
    )
  ).then(
    (r) =>
      r.json() as pAPIResponse<{
        building: ApiBuilding;
        money: Big;
        spend: Big;
      }>
  );

  if (typeof result.statusCode === "number")
    return {
      success: false,
      error: result.message,
    };

  return {
    success: true,
    building: {
      ...result.building,
      business_level_data: {
        ...result.building.business_level_data,
        maxCollect: result.building.business_level_data.max_collect,
        earn: new Big(result.building.business_level_data.earn),
        cost: new Big(result.building.business_level_data.cost),
      },
    },
    money: new Big(result.money),
    spend: new Big(result.spend),
  };
}

export async function collectBuildingIncome(
  _api: BootstrapFetch,
  buildingId: number,
  times = 1
): Promise<
  ResultExtendable<{
    money: Big;
    building: Building;
    earn: Big;
  }>
> {
  const result = await fetch(
    api("building", buildingId, "collect?times=" + times),
    _api()
  ).then(
    (r) =>
      r.json() as pAPIResponse<{
        money: string;
        building: ApiBuilding;
        earn: string;
      }>
  );

  if (typeof result.statusCode === "number")
    return {
      success: false,
      error: result.message,
    };

  return {
    success: true,
    money: new Big(result.money),
    building: {
      ...result.building,
      business_level_data: {
        ...result.building.business_level_data,
        maxCollect: result.building.business_level_data.max_collect,
        earn: new Big(result.building.business_level_data.earn),
        cost: new Big(result.building.business_level_data.cost),
      },
    },
    earn: new Big(result.earn),
  };
}

export async function upgradeBuilding(
  _api: BootstrapFetch,
  buildingId: number,
  times = 1
): Promise<
  ResultExtendable<{
    building: Building;
    money: Big;
    earn: Big;
    cost: Big;
  }>
> {
  const result = await fetch(
    api("building", buildingId, "upgrade", "?times=" + times),
    _api({
      method: "POST",
    })
  ).then(
    (r) =>
      r.json() as pAPIResponse<{
        payload: ApiBuilding;
        money: string;
        earn: string;
        cost: string;
      }>
  );

  if (typeof result.statusCode === "number")
    return {
      success: false,
      error: result.message,
    };

  return {
    success: true,
    building: {
      ...result.payload,
      business_level_data: {
        ...result.payload.business_level_data,
        maxCollect: result.payload.business_level_data.max_collect,
        earn: new Big(result.payload.business_level_data.earn),
        cost: new Big(result.payload.business_level_data.cost),
      },
    },
    money: new Big(result.money),
    cost: new Big(result.cost),
    earn: new Big(result.earn),
  };
}
