import React, {
  useContext,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import useManager, { fakeManager } from "./useManager";

import { createContext } from "react";
import { api } from "../utils";
import Big from "big.js";

import type { APIUser, User } from "../types/user";
import type { APIResponse, BootstrapFetch } from "../types/common";
import type { UserDistrict } from "../types/district";
import type { Building, ApiBuilding } from "../types/building";
import type { Manager } from "./useManager";

import AuthView from "../views/auth";
import TelegramAuthView from "../views/auth/telegram";

type UserDistricts = { [districtId: string]: UserDistrict };
type TelegramStatus = {
  loading: boolean;
  error: string;
  automaticallyGeneratedUsername: string;
};

interface UserContextType {
  loggedIn: boolean;
  loading: boolean;
  isTelegram: boolean;
  userId: string;
  tokenRef: React.MutableRefObject<string>;
  login: (username: string) => Promise<string | void>;
  signup: (username: string, password: string) => Promise<string | void>;
  logout: () => void;
  user: User | null;
  incomePerSecond: Big;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  districts: UserDistricts | null;
  buildings: Building[];
  buildingsManager: Manager<Building>;
  telegramStatus: TelegramStatus;
  telegramLogin: (username?: string) => Promise<string | void>;
  _api: BootstrapFetch;
}

const UserContext = createContext<UserContextType>({
  loggedIn: false,
  loading: false,
  userId: "",
  isTelegram: false,
  tokenRef: { current: "" },
  login: async () => "Loading",
  signup: async () => "Loading",
  logout: () => {},
  user: null,
  incomePerSecond: new Big(0),
  setUser: () => {},
  districts: null,
  buildings: [],
  _api: (options = {}) => options,
  buildingsManager: fakeManager<Building>([]),
  telegramLogin: async () => "Loading",
  telegramStatus: {
    loading: true,
    error: "",
    automaticallyGeneratedUsername: "",
  },
});

export default function useUser(): UserContextType {
  const context = useContext(UserContext);
  return context;
}

export function UserProvider({
  children = null,
  isTelegram = false,
}: {
  children: React.ReactNode;
  isTelegram?: boolean;
}) {
  useEffect(() => {
    window.Big = Big;
  }, []);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(
    () => localStorage.getItem("token") !== null || isTelegram
  );

  const [userId, setUserId] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [districts, setDistricts] = useState<UserDistricts | null>(null);
  const [buildings, buildingsManager] = useManager<Building>([]);

  const tokenRef = useRef<string>(localStorage.getItem("token") || "");

  useEffect(() => {
    if (loading || !loggedIn || buildings.length === 0) return;
    if (!user?.money) return; // Theoretically, this should never happen as if loading is false, user should be set

    const autoBuildings = buildings.filter(
      (building) =>
        building.is_active === true &&
        building.business_level_data.maxCollect === 0
    );
    if (autoBuildings.length === 0) return;

    const now = Math.floor(Date.now() / 1000);
    const desyncIncome = autoBuildings.reduce((total, building) => {
      const lastCollect = Math.floor(
        new Date(building.last_collect).getTime() / 1000
      );
      const desyncTime = Math.floor(now - lastCollect);
      if (desyncTime < 1) return total;

      return total.add(
        building.business_level_data.earn
          .div(building.business_level_data.time)
          .mul(desyncTime)
      );
    }, new Big(0));

    if (desyncIncome.gt(0))
      setUser((e) => ({ ...e!, money: e!.money.add(desyncIncome) }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loggedIn]);
  const [incomePerSecond, setIncomePerSecond] = useState(() => new Big(0));
  useEffect(() => {
    if (loading || !loggedIn || buildings.length === 0) return;
    if (!user?.money) return; // Theoretically, this should never happen as if loading is false, user should be set

    const autoBuildings = buildings.filter(
      (building) =>
        building.is_active === true &&
        building.business_level_data.maxCollect === 0
    );
    if (autoBuildings.length === 0) return setIncomePerSecond(new Big(0));

    const incomePerSecond = autoBuildings.reduce((total, building) => {
      return total.add(
        building.business_level_data.earn.div(building.business_level_data.time)
      );
    }, new Big(0));
    setIncomePerSecond(incomePerSecond);

    if (incomePerSecond.eq(0)) return;

    const i = setInterval(() => {
      setUser((e) => ({ ...e!, money: e!.money.add(incomePerSecond) }));
    }, 1000);

    return () => clearInterval(i);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loggedIn, buildings]);

  const fetchUser = useCallback(async () => {
    const [status, body] = await fetch(api("game"), {
      headers: {
        Authorization: `Bearer ${tokenRef.current}`,
      },
    }).then((result) =>
      Promise.all([
        result.status,
        result.json() as Promise<
          APIResponse<{
            user: APIUser;
            districts: UserDistricts;
            buildings: ApiBuilding[];
          }>
        >,
      ])
    );

    if (status !== 200) {
      setLoading(false);
      setLoggedIn(false);
      setTelegramStatus((e) => ({ ...e, loading: false, error: body.message }));
      return logout();
    }

    setUserId(body.user.id);
    setUser({
      ...body.user,
      exp: new Big(body.user.exp),
      gem: new Big(body.user.gem),
      money: new Big(body.user.money),
    });
    setDistricts(body.districts);
    buildingsManager.setAll(
      body.buildings
        .sort((a, b) => a.id - b.id)
        .map((building: ApiBuilding) => ({
          ...building,
          business_level_data: {
            ...(building.business_level_data ?? {
              time: 1,
            }),
            earn: new Big(building.business_level_data?.earn ?? "0"),
            cost: new Big(building.business_level_data?.cost ?? "0"),
            maxCollect: building.business_level_data?.max_collect ?? 1,
          },
        }))
    );

    setLoggedIn(true);
    setLoading(false);
    setTelegramStatus((e) => ({ ...e, loading: false, error: "" }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback(async (username: string) => {
    setLoading(true);

    const [status, body] = await fetch(api("auth", "login"), {
      method: "POST",
      body: JSON.stringify({ username }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((result) =>
      Promise.all([
        result.status,
        result.json() as Promise<
          APIResponse<{
            access_token: string;
          }>
        >,
      ])
    );

    if (status !== 201) {
      setLoading(false);
      return body.message;
    }

    tokenRef.current = body.access_token;
    localStorage.setItem("token", body.access_token);

    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [telegramStatus, setTelegramStatus] = useState<TelegramStatus>({
    loading: true,
    error: "",
    automaticallyGeneratedUsername: "",
  });
  const telegramLogin = useCallback(async (username: string = "") => {
    setTelegramStatus((e) => ({ ...e, loading: true, error: "" }));

    const initData = window.Telegram.WebApp.initData;
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;

    let prospectiveUsername = !initDataUnsafe.user
      ? ""
      : initDataUnsafe.user?.username || "";

    prospectiveUsername = prospectiveUsername.replace(/[^a-zA-Z0-9]/g, "");

    if (
      prospectiveUsername.length < 3 &&
      typeof initDataUnsafe.user !== "undefined"
    ) {
      prospectiveUsername = `${initDataUnsafe.user.first_name}${initDataUnsafe.user.last_name}`;
      prospectiveUsername = prospectiveUsername
        .replace(/[^a-zA-Z0-9]/g, "")
        .slice(0, 15);
    }

    if (prospectiveUsername.length < 3) {
      prospectiveUsername = `player${Math.floor(Math.random() * 1000000)}`;
    }

    setTelegramStatus((e) => ({
      ...e,
      automaticallyGeneratedUsername: prospectiveUsername,
    }));

    const [status, body] = await fetch(api("auth", "telegram"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initData,
        username: username !== "" ? username : prospectiveUsername,
      }),
    }).then((result) => Promise.all([result.status, result.json()]));

    if (status !== 201) {
      setTelegramStatus((e) => ({
        ...e,
        loading: false,
        error: body.message,
      }));
      return body.message;
    }

    tokenRef.current = body.access_token;
    localStorage.setItem("token", body.access_token);

    setLoading(true);
    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signup = useCallback(async (username: string, password: string) => {
    setLoading(true);

    const [status, body] = await fetch(api("auth", "register"), {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((result) =>
      Promise.all([
        result.status,
        result.json() as Promise<
          APIResponse<{
            access_token: string;
          }>
        >,
      ])
    );

    if (status !== 201) {
      setLoading(false);
      return body.message;
    }

    tokenRef.current = body.access_token;
    localStorage.setItem("token", body.access_token);

    fetchUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    tokenRef.current = "";
    setLoggedIn(false);
    setLoading(false);
    setUserId("");
    setUser(null);
  }, []);

  useEffect(() => {
    if (loading && !isTelegram) {
      fetchUser();
    } else if (isTelegram) {
      if (tokenRef.current === "") telegramLogin();
      else fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bootstrapFetch = useCallback(
    (options: RequestInit = {}, json = false) => {
      return {
        ...(options || {}),
        headers: {
          ...(options.headers || {}),
          Authorization: `Bearer ${tokenRef.current}`,
          ...(json ? { "Content-Type": "application/json" } : {}),
        },
      };
    },
    []
  );

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        loading,
        isTelegram,
        userId,
        tokenRef,
        user,
        setUser,
        login,
        districts,
        logout,
        signup,
        buildings,
        incomePerSecond,
        buildingsManager,
        telegramLogin,
        telegramStatus,
        _api: bootstrapFetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function UserWrapper({
  children = null,
}: {
  children: React.ReactNode;
}) {
  const { loggedIn, loading, isTelegram } = useUser();
  return loading ? (
    <div className="loader center" />
  ) : loggedIn ? (
    <>{children}</>
  ) : isTelegram ? (
    <TelegramAuthView />
  ) : (
    <AuthView />
  );
}
