import { useState, useEffect } from "react";

export default function useNow(endTime: number) {
  const [now, setNow] = useState(() => Math.floor(Date.now() / 1000));

  useEffect(() => {
    let _start = Math.floor(Date.now() / 1000);
    setNow(_start);

    if (endTime < _start) return;

    const interval = setInterval(() => {
      const current = Math.floor(Date.now() / 1000);
      setNow(current);

      if (current >= endTime) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  return now;
}
