import useUser from "../../hooks/useUser";
import { useMemo } from "react";

import styles from "./styles.module.css";
import Big from "big.js";

import { ReactComponent as AirdropIcon } from "../../assets/icons/airdrop.svg";
import { ReactComponent as MoneyIcon } from "../../assets/icons/money.svg";
import { toLocaleString } from "../../utils";

export default function Header() {
  const { user, incomePerSecond } = useUser();

  const [passiveIncome, passiveIncomeUnit] = useMemo(() => {
    if (incomePerSecond.eq(0)) return [new Big(0), ""];
    if (incomePerSecond.gt(1e6)) return [incomePerSecond, "s"];
    if (incomePerSecond.gt(1e4)) return [incomePerSecond.mul(60), "m"];
    if (incomePerSecond.gt(1e3)) return [incomePerSecond.mul(60 * 60), "h"];
    return [incomePerSecond.mul(60 * 60 * 24), "d"];
  }, [incomePerSecond]);
  return (
    <header className={styles.root}>
      <div className={styles.left}>
        <div className={styles.airdrop}>
          <AirdropIcon />
          TBA
        </div>
      </div>
      <div className={styles.right}>
        {!incomePerSecond.eq(0) && (
          <div className={styles.passive}>
            <picture>
              <MoneyIcon />
            </picture>
            {toLocaleString(passiveIncome)}
            <div className={styles.divider} />
            <em>{passiveIncomeUnit.toUpperCase()}</em>
          </div>
        )}

        <div className={styles.money}>
          <em>$</em>
          {toLocaleString(user?.money)}
        </div>
        <div className={styles.user}>
          user account
          <span>{user?.eth_wallet ?? user?.username}</span>
        </div>
      </div>
    </header>
  );
}
