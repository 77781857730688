import styles from "./styles.module.css";

import { NavLink } from "react-router-dom";

export default function Navigation() {
  const className = ({ isActive }: { isActive: boolean }) =>
    `${styles.link} ${isActive ? styles.active : ""}`;
  return (
    <nav className={styles.root}>
      <NavLink className={className} to="/">
        Business
      </NavLink>
      <NavLink className={className} to="/empire">
        Empire
      </NavLink>
      <NavLink className={className} to="/social">
        Social
      </NavLink>
      <NavLink className={className} to="/vip">
        VIP
      </NavLink>
      <NavLink className={className} to="/airdrop">
        Airdrop
      </NavLink>
    </nav>
  );
}
