import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { api } from "./utils";

import App from "./App";
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

document.addEventListener(
  "touchmove",
  function (event) {
    // @ts-ignore
    if (event.scale !== 1) {
      event.preventDefault();
    }
  },
  { passive: false }
);

document.addEventListener(
  "dblclick",
  (event) => {
    event.preventDefault();
  },
  { passive: false }
);

const iphone = () => {
  if (typeof window === `undefined` || typeof navigator === `undefined`)
    return false;

  return /iPhone/i.test(
    navigator.userAgent ||
      navigator.vendor || // @ts-ignore
      (window.opera && window.opera.toString() === `[object Opera]`)
  );
};
function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}
if (iphone()) {
  document.body.style.setProperty("--navigation-padding", "20px");
} else if (isMobile()) {
  document.body.style.setProperty("--navigation-padding", "10px");
}

const params = new URLSearchParams(window.location.search);
let isTelegram = false,
  deferLoading = false;

function bootstrapTelegramApp() {
  window.Telegram.WebApp.ready();
  window.Telegram.WebApp.expand();
}

if (params.has("source") && params.get("source") === "telegram") {
  isTelegram = true;

  if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
    bootstrapTelegramApp();

    const initData = window.Telegram.WebApp.initData;
    if (typeof initData !== "string" || initData.trim() === "") {
      console.error("App loaded in Telegram mode but no initData was found.");
      console.error(
        "Telegram WebApp initialization failed, reverting to browser mode."
      );

      isTelegram = false;
    }

    // We can render the app now
  } else {
    // Telegram.js hasn't loaded yet, defer rendering the app
    deferLoading = true;

    console.warn("Waiting for Telegram.js to load before rendering the app.");

    window.addEventListener("telegram-loaded", () => {
      console.warn("Telegram.js has loaded, rendering the app now.");

      bootstrapTelegramApp();

      const initData = window.Telegram.WebApp.initData;
      if (typeof initData !== "string" || initData.trim() === "") {
        console.error("App loaded in Telegram mode but no initData was found.");
        console.error(
          "Telegram WebApp initialization failed, reverting to browser mode."
        );

        isTelegram = false;
      }

      root.render(
        <React.StrictMode>
          <App isTelegram={isTelegram} />
        </React.StrictMode>
      );
    });
  }
}

// if we aren't waiting for Telegram.js to load, render the app
if (!deferLoading)
  root.render(
    <React.StrictMode>
      <App isTelegram={isTelegram} />
    </React.StrictMode>
  );

/*
const params = new URLSearchParams(window.location.search);
if (params.has("source") && params.get("source") === "telegram") {
  if (typeof window.Telegram !== "undefined" && window.Telegram.WebApp) {
    // Initialize the Telegram Web App
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();

    // Retrieve user data
    const initData = window.Telegram.WebApp.initData;
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
    const user = initDataUnsafe.user;

    console.log("USER", user); // Logs the Telegram user interacting with your app
    console.log("FULL", initData); // Logs the Telegram Web App initialization data

    // You can also close the app or send a confirmation message back to Telegram
    // Telegram.WebApp.close();

    fetch(api("auth", "telegram"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initData,
      }),
    })
      .then((r) => Promise.resolve([r.status, r.text()]))
      .then(([status, text]) => {
        console.log("AUTH STATUS", status, text);
      });
  } else {
    console.warn("Telegram Web App is not available.");
    // Optionally handle non-Telegram environments here
  }
}*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(); // i don't think so
