import { useEffect, useState } from "react";
import useUser from "../../hooks/useUser";

import styles from "./styles.module.css";

export default function TelegramAuthView() {
  const { telegramLogin, telegramStatus } = useUser();

  const [username, setUsername] = useState(
    telegramStatus.automaticallyGeneratedUsername
  );

  useEffect(() => {
    // when the username changes, update the username field, if it's empty to avoid overriding user input
    if (username === "")
      setUsername(telegramStatus.automaticallyGeneratedUsername);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [telegramStatus.automaticallyGeneratedUsername]);

  return (
    <form
      className={styles.root}
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (telegramStatus.loading) return;

        const result = await telegramLogin(username);
        if (typeof result === "string") {
          alert(result);
        }

        return false;
      }}
    >
      <label htmlFor="username">Username</label>
      <input
        id="username"
        autoComplete="username"
        type="text"
        value={username}
        disabled={telegramStatus.loading}
        onChange={(e) => setUsername(e.target.value)}
      />
      {telegramStatus.error !== "" && (
        <div className={styles.error}>{telegramStatus.error}</div>
      )}
      <div className={styles.spacer} />
      <button
        className={styles.submit}
        disabled={telegramStatus.loading}
        type="submit"
      >
        {telegramStatus.loading ? "Loading..." : "Login"}
      </button>
    </form>
  );
}
