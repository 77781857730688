import { useState } from "react";
import useUser from "../../hooks/useUser";

import styles from "./styles.module.css";

export default function AuthView() {
  const { login, signup: signupUser } = useUser();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [signup, setSignup] = useState(false);

  return (
    <form
      className={styles.root}
      onSubmit={async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);

        if (!signup) {
          const result = await login(username);
          if (typeof result === "string") {
            alert(result);
            setLoading(false);
          }
        } else {
          const result = await signupUser(username, password);
          if (typeof result === "string") {
            alert(result);
            setLoading(false);
          }
        }

        return false;
      }}
    >
      <label htmlFor="username">Username</label>
      <input
        id="username"
        autoComplete="username"
        type="text"
        value={username}
        disabled={loading}
        onChange={(e) => setUsername(e.target.value)}
      />
      {signup && (
        <>
          <label htmlFor="password">Password</label>
          <input
            id="password"
            autoComplete="new-password"
            type="password"
            value={password}
            disabled={loading}
            onChange={(e) => setPassword(e.target.value)}
          />
        </>
      )}
      <div className={styles.spacer} />
      <button
        className={styles.submit}
        disabled={loading}
        type="button"
        onClick={() => setSignup(!signup)}
      >
        {signup ? "Return to Login" : "Create Account"}
      </button>
      <button className={styles.submit} disabled={loading} type="submit">
        {signup ? "Signup" : "Login"}
      </button>
    </form>
  );
}
