import { useEffect, useState, useMemo } from "react";
import useUser from "../../../hooks/useUser";
import useConfig from "../../../hooks/useConfig";

import styles from "./styles.module.css";

import type { Building } from "../../../types/building";
import Big from "big.js";

/*
Milestones are assumed at every 20 for now, and max level is 100.
*/

export default function BuildingProgress({ building }: { building: Building }) {
  const { user } = useUser();
  const { ready, patterns, milestones } = useConfig();

  const MilestoneThreshold = useMemo(
    () => milestones[building.business_pattern_id][0],
    [ready, building.business_pattern_id]
  );

  // Base level = 0, 20, 40, 60, 80
  // Base is subtracted from current total level to get the current progress
  const [base, currentLevel] = useMemo(() => {
    if (building.level === building.business.max_level)
      return [building.level - MilestoneThreshold, MilestoneThreshold];
    const base =
      Math.floor(building.level / MilestoneThreshold) * MilestoneThreshold;
    return [base, building.level - base];
  }, [building.level, building.business.max_level]);

  const upgradable = useMemo(() => {
    if (
      !ready ||
      !patterns[building.business_pattern_id] ||
      building.level === building.business.max_level ||
      !user?.money ||
      user.money.eq(0)
    )
      return 0;

    const businessLevels = patterns[building.business_pattern_id];
    const businessNextLevels = businessLevels.slice(
      businessLevels.findIndex((level) => level.level === building.level) + 1
    );

    // We don't know what the next level is, so we can't calculate the progress
    if (businessNextLevels.length === 0) return 0;

    let money: Big = user!.money,
      upgradableLevels = 0;
    for (const level of businessNextLevels) {
      if (money.gte(level.cost)) {
        money = money.minus(level.cost);
        upgradableLevels++;
      } else break;
    }

    return upgradableLevels;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.money,
    ready,
    patterns,
    building.level,
    building.business.max_level,
  ]);

  const remaining = useMemo(
    () => MilestoneThreshold - currentLevel - upgradable,
    [currentLevel, upgradable]
  );

  return (
    <div className={styles.root}>
      <div className={styles.label}>Lv.{building.level}</div>
      <BuildingProgressBar
        current={currentLevel}
        upgradable={upgradable}
        remaining={remaining}
      />
    </div>
  );
}

export function BuildingProgressInfo({
  currentLevel,
  upgradable,
  remaining,
  base,
}: {
  currentLevel: number;
  upgradable: number;
  remaining: number;
  base: number;
}) {
  return (
    <div className={styles.root}>
      {/*<pre className={styles.debug}>
        {JSON.stringify({ currentLevel, upgradable, remaining, base }, null, 2)}
      </pre>*/}
      <div className={styles.label}>Lv.{base + currentLevel}</div>
      <BuildingProgressBar
        current={currentLevel}
        upgradable={upgradable}
        remaining={remaining}
      />
    </div>
  );
}

export function BuildingProgressBar({
  current,
  upgradable,
  remaining,
}: {
  current: number;
  upgradable: number;
  remaining: number;
}) {
  type Dividers = {
    first: boolean;
    second: boolean;
  };
  const [gridTemplateColumns, dividers] = useMemo(() => {
    const columns = [],
      dividers: Dividers = {
        first: false,
        second: false,
      };

    if (current > 0) {
      columns.push(`${current}fr`);
    }

    if (upgradable > 0) {
      if (columns.length > 0) {
        //columns.push("1fr");
        dividers.first = true;
      }
      columns.push(`${upgradable}fr`);
    }

    if (remaining > 0) {
      if (columns.length > 0) {
        // columns.push("1fr");
        dividers.second = true;
      }
      columns.push(`${remaining}fr`);
    }

    return [
      columns.join(" "),
      {
        first: false,
        second: false,
      },
    ];
  }, [current, upgradable, remaining]);

  return (
    <div
      className={styles.bar}
      style={{
        gridTemplateColumns,
      }}
    >
      {current > 0 && <div className={styles.solid} />}
      {dividers.first && <div className={styles.divider} />}
      {upgradable > 0 && <div className={styles.half} />}
      {dividers.second && <div className={styles.divider} />}
      {remaining > 0 && <div className={styles.empty} />}
    </div>
  );
}
